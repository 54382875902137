import {Segment} from "semantic-ui-react";

import styles from "widgets/requests/request-row/styles.module.scss"
import {StepFolderCard} from "entities/steps/ui/step-folder-card";
import {StepFolderControl} from "features/step-folders/step-folder-control";

export const StepFolderRow = ({folder}) => {

  return (
      <Segment className={styles.root}>
        <StepFolderCard folder={folder}/>
        <StepFolderControl folder={folder} />
      </Segment>
  )
}