import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  access_token: localStorage.getItem('access_token'),
  refresh_token: localStorage.getItem('refresh_token'),
  user: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userAuth: (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
    userUnAuth: (state) => {
      state.access_token = null;
      state.refresh_token = null;
      state.user = null;
    },
    userSave: (state, action) => {
      state.user = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { userAuth, userUnAuth, userSave } = userSlice.actions
export default userSlice.reducer
