import React, {useState} from "react";
import { toast } from "react-toastify";
import {Form, Segment} from "semantic-ui-react";

import { login } from "entities/user/api";

export const LoginForm = (onComplete) => {

  const [form, setForm] = useState({
    email: "",
    password: ""
  })


  const submit = () => {
    login(form.email, form.password)
      .catch(() => {
        toast.error("Произошла ошибка при авторизации. Проверьте данные для входа.")
      })
  }

  return (
    <Segment stacked>
      <Form size='large'>
        <Form.Input
          fluid icon='user'
          iconPosition='left'
          placeholder='E-mail'
          onChange={(e) => setForm({...form, email: e.target.value})}
        />
        <Form.Input
          fluid
          icon='lock'
          iconPosition='left'
          placeholder='Пароль'
          type='password'
          onChange={(e) => setForm({...form, password: e.target.value})}
        />
        <Form.Button
          type="submit"
          color='blue'
          fluid
          size='large'
          onClick={submit}
        >
          Войти
        </Form.Button>
      </Form>
    </Segment>
  )
}