import {Header, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {RequestConnectionControl} from "features/requests/request-connection-control";
import {getRequest} from "entities/requests/api";
import {toast} from "react-toastify";

export const RequestConnectionRow = ({parent_request, child_request, connection_id, value_in}) => {

  const updateRequest = (isDelete = false) => {
    getRequest(parent_request.id)
      .then(() => {
        if (!isDelete) {
          toast.success("Связь успешно обновлена!")
        }
      })
  }

  return (
    <>
      {child_request ? (
        <Segment>
          <Header as="h3"><Link to={`/requests/${child_request.id}`}>{child_request.name}</Link></Header>
          <RequestConnectionControl connection_id={connection_id} connection_value_in={value_in} onSave={updateRequest}/>
        </Segment>)
      : null}
    </>
  )

}