import {Segment} from "semantic-ui-react";
import {StepCard} from "entities/steps/ui/step-card";
import {StepControl} from "features/steps/step-control";

import styles from "widgets/steps/step-row/styles.module.scss"

export const StepRow = ({step}) => {

  return (
      <Segment className={styles.root}>
        <StepCard step={step}/>
        <StepControl step={step}/>
      </Segment>
  )
}