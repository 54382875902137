import {Button, Modal} from "semantic-ui-react";
import {StepForm} from "features/steps/step-form";

export const StepEditModal = ({isOpen, step, isNew, onOpen, onClose}) => {

  return (
    <Modal
      closeIcon
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Modal.Header>
        {isNew ? 'Создание нового шага' : `Окно редактирования шага ${step?.name}`}
      </Modal.Header>
      <Modal.Content>
        <StepForm isNew={isNew} step={step} onSave={onClose}></StepForm>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" onClick={onClose}>Отменить</Button>
      </Modal.Actions>
    </Modal>
  )
}