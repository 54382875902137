import {Header, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";

export const RequestFolderCard = ({folder}) => {

  return (
      <div>
        <Header as="h3">
          <Link to={`/requests/folder/${folder.id}/`}>
            <Icon name={"folder"}/>[{folder.id}] {folder.name}
          </Link>
        </Header>
      </div>
  )
}