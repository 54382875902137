import {Routing} from "pages";
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Provider as StoreProvider} from 'react-redux'
import { appStore } from "app/redux/appStore";

const App = () => {
  return (
    <StoreProvider store={appStore}>
      <BrowserRouter>
        <div className="App">
          <Routing />
        </div>
      <ToastContainer/>
     </BrowserRouter>
    </StoreProvider>
  );
}

export default App;
