import {Segment} from "semantic-ui-react";
import styles from "pages/root.module.scss";
import {useEffect} from "react";
import {getAllRequests} from "entities/requests/api";
import {TrialRequestCard} from "widgets/trial-request-card";

export const TrialRequestPage = () => {


  useEffect(() => {
    getAllRequests()
  }, [])

  return (
      <Segment className={styles.segment}>
        <TrialRequestCard />
      </Segment>
  )
}