import axios from "axios";
import {toast} from "react-toastify";
import {appStore} from "app/redux/appStore";
import {userUnAuth} from "entities/user/model/slice";

let headers = {
  Accept: "application/json",
};
const token = localStorage.getItem("access_token");
if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}

console.log(process.env.REACT_APP_DEFAULT_API_PATH)
const api = axios.create({
  baseURL: process.env.REACT_APP_DEFAULT_API_PATH || "/api/v1",
  timeout: 1000,
  headers,
});


// TODO: Не знаю как не нарушать паттерн, чтобы сделать выход если токен недействительный
const unAuthOn401 = (error) => {
  if ((error.response?.status === 401) || !error.response) {
    appStore.dispatch(userUnAuth())
    localStorage.clear()
    // delete api.defaults.headers.Authorization
  } else {
    if (error.response?.status !== 400) {
      toast.error(error.response?.data.error?.error_description || error.message)
    }
    return Promise.reject({ ...error })
  }
}

api.interceptors.response.use(undefined, unAuthOn401)

export default api