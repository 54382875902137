import {Button, Modal} from "semantic-ui-react";
import {RequestForm} from "features/requests/request-form";

export const RequestEditModal = ({isOpen, request, isNew, onOpen, onClose}) => {

  return (
    <Modal
      closeIcon
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Modal.Header>
        {isNew ? 'Создание нового запроса' : `Окно редактирования запроса ${request?.name}`}
      </Modal.Header>
      <Modal.Content>
        <RequestForm isNew={isNew} request={request} onSave={onClose}></RequestForm>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" onClick={onClose}>Отменить</Button>
      </Modal.Actions>
    </Modal>
  )
}