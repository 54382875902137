import {useEffect, useState} from "react";
import {Form} from "semantic-ui-react";
import {useSelector} from "react-redux";
import {getRequests} from "entities/requests/model/selectors";


export const RequestLogsListFilter = ({onFilterChange}) => {
  const [form, setForm] = useState({})
  const [requestOptions, setRequestOptions] = useState([])

  const requests = useSelector(getRequests)


  useEffect(() => {
    onFilterChange(form)
  }, [form, onFilterChange])

  useEffect(() => {
    const options = [{key: 0, value: 0, text: `Любой`}]
    Object.values(requests).forEach((request) => {
      options.push({key: request.id, value: request.id, text: `[${request.id}] ${request.name}`})
    })
    setRequestOptions(options)
  }, [requests])

  return (
      <>
        <Form>
          <Form.Select
              placeholder="Выберите запрос"
              options={requestOptions}
              value={form.start_request}
              onChange={(e, data) => setForm({...form, start_request: data.value})}
              label="Запрос"
              required
          />
        </Form>
      </>
  )
}