import {Header, Segment} from "semantic-ui-react";
import {useSelector} from "react-redux";
import styles from "pages/root.module.scss";
import {getRequestFolders} from "entities/requests/model/selectors";
import {RequestFolderRow} from "widgets/request-folders/request-folder-row";
import {RequestFolderAdd} from "features/request-folders/request-folder-add";
import {useEffect} from "react";


export const RequestFoldersPage = () => {

  const folders = useSelector(getRequestFolders)

  return (
    <Segment  className={styles.segment}>
      <Header as={"h2"} textAlign={"center"}>
        Список папок с запросами
      </Header>
      <RequestFolderAdd/>

      {Object.values(folders).map((folder, idx) => (
          <RequestFolderRow folder={folder} key={idx}/>
      ))}
    </Segment>
  )
}