import { appStore } from "app/redux/appStore";
import {userAuth, userSave, userUnAuth} from "entities/user/model/slice"
import api from "shared/api";

export const login = async (email, password) => {

  return api.post("/login/", { username: email, password })
    .then((response) => {
      const access_token = response.data.access_token
      const refresh_token = response.data.refresh_token
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
      api.defaults.headers.Authorization = `Bearer ${access_token}`;
      appStore.dispatch(userAuth({
        access_token,
        refresh_token
      }))
    })
}

export const logout = async () => {
  const refresh_token = appStore.getState().user.refresh_token
  return api.post("/logout/", {refresh_token})
      .then(() => {
        appStore.dispatch(userUnAuth())
        localStorage.clear()
      })
}

export const getMe = async () => {
  return api.get("/me/")
      .then((response) => {
        appStore.dispatch(userSave(response?.data.profile))
      })
}