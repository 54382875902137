import {Button, Icon} from "semantic-ui-react";
import {useState} from "react";
import {RequestFolderEditModal} from "widgets/request-folders/request-folder-edit-modal";


export const RequestFolderAdd = () => {

  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }


  return (
      <>
        <Button color="green" onClick={openModal}><Icon name="plus"/>Добавить папку с запросами</Button>
        <RequestFolderEditModal
          isOpen={isOpen}
          isNew={true}
          folder={null}
          onClose={closeModal}
        />
      </>
  )
}