import {Divider, Form} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {createStep, editStep} from "entities/steps/api";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {getStepFolders} from "entities/steps/model/selectors";

export const StepForm = ({isNew, step, onSave}) => {

  const OPENAI_MODEL_OPTIONS = [
    {text: "gpt-4", value: "gpt-4"},
    {text: "gpt-3.5-turbo-16k", value: "gpt-3.5-turbo-16k"},
    {text: "gpt-3.5-turbo", value: "gpt-3.5-turbo"},
  ]
  const ANSWER_TYPES_OPTIONS = [
    {text: "Текст", value: "text"},
    {text: "Число", value: "number"},
    {text: "JSON", value: "JSON"},
  ]

  const DEFAULT_FORM = {
    name: "Step",
    system_text: "",
    answer_type: "text",
    openai_model: "gpt-4",
    max_tokens: "128",
    temperature: "0.5",
  }

  const [form, setForm] = useState(DEFAULT_FORM)
  const [errors, serErrors] = useState({})
  const [foldersOptions, setFoldersOptions] = useState([])
  const folders = useSelector(getStepFolders)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (isNew) {
      createStep(form)
        .then(() => {
          toast.success(`Новый шаг ${form.name} успешно создан!`)
          serErrors([])
          onSave()
        })
        .catch((error) => {
          serErrors(error.response?.data || [])
        })
    } else {
      editStep(form)
        .then(() => {
          toast.success(`Шаг ${form.name} успешно обновлен!`)
          serErrors([])
          onSave()
        })
        .catch((error) => {
          serErrors(error.response?.data || [])
        })
    }
  }

  const resetForm = () => {
    setForm({...DEFAULT_FORM, id: step?.id})
  }
  useEffect(() => {
    if (!isNew) {
      setForm(step)
    }
  }, [step, isNew])

  useEffect(() => {
    let options = []
    Object.values(folders).forEach((folder) => {
      options = [...options, {key: folder.id, value: folder.id, text: folder.name}]
    })
    setFoldersOptions(options)
  }, [folders])

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Input
          value={form.name}
          onChange={(e) => setForm({...form, name: e.target.value})}
          error={errors.name}
          label="Название шага"
          placeholder="Введите название шага"
          required
        />
        <Form.TextArea
          value={form.preprompt}
          onChange={(e) => setForm({...form, preprompt: e.target.value})}
          error={errors.preprompt}
          label="Препромпт"
          placeholder="Введите препромпт"
          required
        />
        <Form.TextArea
          value={form.system_text}
          onChange={(e) => setForm({...form, system_text: e.target.value})}
          error={errors.system_text}
          label="Текст системы"
          placeholder="Введите системный текст"
        />
        <Form.Group widths={"equal"}>
          <Form.Select
              options={OPENAI_MODEL_OPTIONS}
              value={form.openai_model}
              onChange={(e, data) => setForm({...form, openai_model: data.value})}
              error={errors.openai_model}
              label="Модель OpenAI"
          />
          <Form.Select
              options={ANSWER_TYPES_OPTIONS}
              value={form.answer_type}
              error={errors.answer_type}
              onChange={(e, data) => setForm({...form, answer_type: data.value})}
              label="Тип ответа"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
              label="Максимальное число токенов"
              placeholder="Введите максимальное число токенов"
              type="number"
              value={form.max_tokens}
              onChange={(e) => setForm({...form, max_tokens: e.target.value})}
              error={errors.max_tokens}
              required
          >
            <input type="number" min="0"/>
          </Form.Input>
          <Form.Input
              label="Temperature"
              placeholder="Введите уровень temperature"
              type="number"
              value={form.temperature}
              onChange={(e) => setForm({...form, temperature: e.target.value})}
              error={errors.temperature}
              required
          >
            <input type="number" min="0" step="0.1" max="1"/>
          </Form.Input>
        </Form.Group>
        <Divider/>
        <Form.Select
          options={foldersOptions}
          value={form.folder}
          error={errors.folder}
          onChange={(e, data) => setForm({...form, folder: data.value})}
          label="Папка"
          required
        />
        <Form.Button color="orange" basic onClick={resetForm}>Сбросить</Form.Button>
        <Form.Button color="green" type="submit">Сохранить</Form.Button>
      </Form>
    </>
  )
}
