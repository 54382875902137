import {RequestLogCard} from "entities/requests_logs/ui/request-log-card";

export const RequestLogRow = ({request}) => {


  return (
      <>
        <RequestLogCard request={request}/>
      </>
  )
}