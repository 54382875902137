import {Divider, Header, Loader, Segment} from "semantic-ui-react";
import {StepForm} from "features/steps/step-form";
import {useParams} from "react-router";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {getCurrentStep} from "entities/steps/model/selectors";
import {getStep} from "entities/steps/api";

import styles from "pages/root.module.scss"
import {StepControl} from "features/steps/step-control";

export const StepsDetailsPage = () => {

  const {stepId} = useParams()
  const step = useSelector(getCurrentStep)

  useEffect(() => {
    getStep(stepId)
  }, [stepId])


  return (
      <Segment className={styles.segment}>
        {step ?
          (<>
            <Header as={"h2"}>Редактирование модели {step.name}</Header>
            <StepForm step={step} isNew={false}/>
            <Divider />
            <StepControl step={step} innerText="Удалить модель"/>
          </>)
         : <Loader />}
      </Segment>
  )
}