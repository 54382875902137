import { Button, Modal } from "semantic-ui-react";
import { RequestsFolderForm } from "features/request-folders/request-folder-form";

export const RequestFolderEditModal = ({
  isOpen,
  folder,
  isNew,
  onOpen,
  onClose,
}) => {
  return (
    <Modal closeIcon open={isOpen} onOpen={onOpen} onClose={onClose}>
      <Modal.Header>
        {isNew
          ? "Создание новой папки с запросами"
          : `Окно редактирования папки с запросами ${folder?.name}`}
      </Modal.Header>
      <Modal.Content>
        <RequestsFolderForm isNew={isNew} folder={folder} onSave={onClose} />
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" onClick={onClose}>
          Отменить
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
