import { Form } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { createStepFolder, editStepFolder } from "entities/steps/api";
import { toast } from "react-toastify";
import { createRequestFolder, editRequestFolder } from "entities/requests/api";

export const RequestsFolderForm = ({ isNew, folder, onSave }) => {
  const DEFAULT_FORM = {
    name: "folder",
    slug: "folder_slug",
  };

  const [form, setForm] = useState(DEFAULT_FORM);
  const [errors, serErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNew) {
      createRequestFolder(form)
        .then(() => {
          toast.success(`Новая папка ${form.name} успешно создана!`);
          serErrors([]);
          onSave();
        })
        .catch((error) => {
          serErrors(error.response?.data || []);
        });
    } else {
      editRequestFolder(form)
        .then(() => {
          toast.success(`Папка ${form.name} успешно обновлена!`);
          serErrors([]);
          onSave();
        })
        .catch((error) => {
          serErrors(error.response?.data || []);
        });
    }
  };

  const resetForm = () => {
    setForm({ ...DEFAULT_FORM, id: folder?.id });
  };
  useEffect(() => {
    if (!isNew) {
      setForm(folder);
    }
  }, [folder, isNew]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Input
          value={form.name}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          error={errors.name}
          label="Название папки"
          placeholder="Введите название папки"
          required
        />
        <Form.TextArea
          value={form.slug}
          onChange={(e) => setForm({ ...form, slug: e.target.value })}
          error={errors.slug}
          label="Slug"
          placeholder="Введите slug"
          required
        />
        <Form.Button color="orange" basic onClick={resetForm}>
          Сбросить
        </Form.Button>
        <Form.Button color="green" type="submit">
          Сохранить
        </Form.Button>
      </Form>
    </>
  );
};
