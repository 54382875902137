import {RequestLogsListFilter} from "features/requests/request-logs-list-filter";
import {Divider} from "semantic-ui-react";
import {RequestLogListReview} from "entities/requests_logs/ui/request-log-list-review";
import {RequestLogsListSort} from "features/requests/request-logs-list-sort";


export const RequestListHeader = ({onFilterChange, onSortChange}) => {


  return (
      <>
        <RequestLogsListFilter onFilterChange={onFilterChange}/>
        <Divider/>
        <RequestLogsListSort onSortChange={onSortChange} />
        <RequestLogListReview />
        <Divider/>
      </>
  )
}