import {Divider, Form, Icon, Label, Popup} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getSteps} from "entities/steps/model/selectors";
import {createRequest, editRequest} from "entities/requests/api";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {getRequestFolders} from "entities/requests/model/selectors";


export const RequestForm = ({isNew, request, onSave}) => {
  const DEFAULT_FORM = {
    name: "Request"
  }
  const [form, setForm] = useState(DEFAULT_FORM)
  const [stepOptions, setStepOptions] = useState([])
  const [foldersOptions, setFoldersOptions] = useState([])
  const folders = useSelector(getRequestFolders)
  const [errors, serErrors] = useState({})

  const steps = useSelector(getSteps)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (isNew) {
      createRequest(form)
        .then(() => {
          toast.success(`Новый запрос ${form.name} успешно создан!`)
          serErrors([])
          onSave()
        })
        .catch((error) => {
          console.log(error)
          serErrors(error.response?.data || [])
        })
    } else {
      editRequest(form)
        .then(() => {
          toast.success(`Запрос ${form.name} успешно изменен!`)
          serErrors([])
          onSave()
        })
        .catch((error) => {
          serErrors(error.response?.data || [])
        })
    }
  }

  const resetForm = () => {
    setForm({...DEFAULT_FORM, child_requests: request?.child_requests, id: request?.id})
  }
  useEffect(() => {
    if (!isNew) {
      setForm(request)
    }
  }, [request, isNew])

  useEffect(() => {
    const options = []
    Object.values(steps).forEach((step) => {
      options.push({key: step.id, value: step.id, text: `[${step.id}] ${step.name} (${step.openai_model})`})
    })
    setStepOptions(options)
  }, [steps])

  useEffect(() => {
    let options = []
    Object.values(folders).forEach((folder) => {
      options = [...options, {key: folder.id, value: folder.id, text: folder.name}]
    })
    setFoldersOptions(options)
  }, [folders])

  return (
      <>
        <Form onSubmit={handleSubmit}>
          <Form.Input
            value={form.name}
            onChange={(e) => setForm({...form, name: e.target.value})}
            error={errors.name}
            label="Название запроса"
            placeholder="Введите название запроса"
            required
          />
          <Form.Select
              placeholder="Выберите шаг"
              options={stepOptions}
              value={form.step}
              onChange={(e, data) => setForm({...form, step: data.value})}
              error={errors.step}
              label="Модель OpenAI"
              required
          />
          {steps[form.step] ? (
            <Popup
            content="Перейти к модели"
            trigger={
              <Link to={`/step/${form.step}`}>
                <Label color="blue" pointing basic><Icon color="blue" name='folder'/>
                  {steps[form.step].name}
                </Label>
              </Link>
              }
            />)
          : null}
          <Divider />
          <Form.Select
            options={foldersOptions}
            value={form.folder}
            error={errors.folder}
            onChange={(e, data) => setForm({...form, folder: data.value})}
            label="Папка"
            required
          />
          <Form.Button color="orange" basic onClick={resetForm}>Сбросить</Form.Button>
          <Form.Button color="green" type="submit">Сохранить</Form.Button>
        </Form>
      </>
  )
}