import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  requests: {},
  folders: {},
  currentRequest: null,
}

export const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    saveRequest: (state, action) => {
      state.requests[action.payload.id] = action.payload
    },
    saveRequests: (state, action) => {
      action.payload.forEach((request) => state.requests[request.id] = request)
    },
    clearRequest: (state, action) => {
      delete state.requests[action.payload]
    },
    setCurrentRequest: (state, action) => {
      state.currentRequest = action.payload
    },
    saveRequestFolder: (state, action) => {
      state.folders[action.payload.id] = action.payload
    },
    saveRequestFolders: (state, action) => {
      action.payload.forEach((folder) => state.folders[folder.id] = folder)
    },
    clearRequestFolder: (state, action) => {
      delete state.folders[action.payload]
    },
  },
})

// Action creators are generated for each case reducer function
export const { saveRequest, saveRequests, clearRequest, setCurrentRequest,
  saveRequestFolder, saveRequestFolders, clearRequestFolder } = requestsSlice.actions
export default requestsSlice.reducer
