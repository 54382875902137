import {Button, Icon} from "semantic-ui-react";
import {useState} from "react";
import {StepFolderEditModal} from "widgets/step-folders/step-folder-edit-modal";


export const StepFolderAdd = () => {

  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }


  return (
      <>
        <Button color="green" onClick={openModal}><Icon name="plus"/>Добавить папку</Button>
        <StepFolderEditModal
          isOpen={isOpen}
          isNew={true}
          folder={null}
          onClose={closeModal}
        />
      </>
  )
}