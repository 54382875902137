import {TrialRequestForm} from "features/trial-request-form";

export const TrialRequestCard = () => {


  return (
    <>
      <TrialRequestForm />
    </>
  )
}