import {Button, Confirm, Icon} from "semantic-ui-react";
import {deleteStepById} from "entities/steps/api";
import {useState} from "react";


export const StepControl = ({step, innerText}) => {

  const [confirmIsOpen, setConfirmIsOpen] = useState(false)

  const confirmDelete = () => {
    deleteStepById(step.id)
  }

  const toggleConfirm = () => {
    setConfirmIsOpen(!confirmIsOpen)
  }

  return (
    <div>
      <Button onClick={toggleConfirm} basic color="red"><Icon name="trash alternate outline"/>{innerText}</Button>
      <Confirm
          open={confirmIsOpen}
          header={`Вы действительно хотите удалить шаг ${step.name}?`}
          content={null}
          onCancel={toggleConfirm}
          cancelButton="Отменить"
          onConfirm={confirmDelete}
          confirmButton="Удалить"
        />
    </div>
  )
}