import {Button, Label} from "semantic-ui-react";
import {useState} from "react";


export const RequestLogsListSort = ({onSortChange}) => {

  const [sortOrder, setSortOrder] = useState(true) // true по возрастанию, false по убыванию
  const toggleSortOrder = () => {
    setSortOrder(!sortOrder)
    onSortChange(sortOrder)
  }
  return (
      <>
        <Button onClick={toggleSortOrder} icon={sortOrder ? "sort amount up": "sort amount down"}></Button>
      </>
  )
}