import {useEffect, useState} from "react";
import {Divider, Icon, Item, Label, Message, Popup, Segment} from "semantic-ui-react";
import styles from "entities/requests_logs/ui/styles.module.scss";
import {Link} from "react-router-dom";

const STATUS_LABELS = {
  success: {
    color: "green",
    text: "Успешно"
  },
  in_process: {
    color: "yellow",
    text: "В процессе"
  },
  error: {
    color: "red",
    text: "Ошибка"
  }
}
export const RequestLogCard = ({request}) => {

  const [statusLabelText, setStatusLabelText] = useState("")
  const [prettyParams, setPrettyParams] = useState("")
  const [prettyDatetime, setPrettyDatetime] = useState("")

  useEffect(() => {
    setStatusLabelText(STATUS_LABELS[request.response?.status]?.text || request.response?.status)
  }, [request])

  useEffect(() => {
    if (request.params) {
      setPrettyParams(JSON.stringify(request.params, null, 2))
    }
  }, [request])

  useEffect(() => {
    if (request.created_at) {
      const datetime = new Date(request.created_at)
      setPrettyDatetime(new Intl.DateTimeFormat("ru-RU", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(datetime))
    }
  }, [request.created_at])


  return (
      <Segment>
        <Item>
          <Item.Content>
            <Item.Header className={styles.header} as="h3">
              <div>
                <Icon name="user"/>{request.user_email}
                <Popup
                    trigger={
                      <Link to={`/requests/${request.start_request}`}> <Icon name="arrow circle right"/> {request.start_request}</Link>
                    }
                    content="Перейти к стартовому шагу"
                />
              </div>
              <Label>id: {request.id} | {prettyDatetime}</Label>
            </Item.Header>
            <Divider/>
            <Item.Description>
              <Message>
                <Message.Header><Label>Входные параметры</Label></Message.Header>
                {prettyParams}
              </Message>
              {request.response ? (
                  <Message>
                    <Message.Header>
                      <Label color={STATUS_LABELS[request.response.status]?.color}>
                        Статус ответа: {statusLabelText}
                      </Label>
                    </Message.Header>
                    {request.response.text}
                  </Message>
              ) : null}
            </Item.Description>
          </Item.Content>
        </Item>
      </Segment>
  )
}