import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getRequests} from "entities/requests/model/selectors";
import {Form, Header, Icon, Segment} from "semantic-ui-react";
import {createConnection} from "entities/requests/api";
import {toast} from "react-toastify";


export const RequestConnectionAdd = ({parent_request, onSave}) => {

  const [form, setForm] = useState({})
  const [errors, setErrors] = useState([])
  const [childRequestOptions, setChildRequestOptions] = useState([])
  const all_requests = useSelector(getRequests)

  const handleSubmit = () => {
    createConnection({...form, parent_request: parent_request.id})
      .then(() => {
        toast.success("Новая связь успешно создана!")
        setErrors([])
        setForm({})
        onSave()
      })
      .catch((error) => {
        setErrors(error.response?.data || [])
      })

  }

  useEffect(() => {
    const options = []
    Object.values(all_requests).forEach((request) => {
      if (request.id !== parent_request.id
          && !parent_request.child_requests.find((child) => child.child_request === request.id)
          && !request.child_requests.find((child) => child.child_request === parent_request.id)
      ) {
        options.push({value: request.id, text: request.name})
      }
    })
    setChildRequestOptions(options)
  }, [all_requests, parent_request])
  
  return (
      <Segment>
        {childRequestOptions.length ? (
          <Form onSubmit={handleSubmit}>
            <Form.Group widths={"equal"}>
              <Form.Select
                  placeholder="Выберите следующий запрос"
                  options={childRequestOptions}
                  value={form.child_request}
                  error={errors.child_request}
                  onChange={(e, data) => setForm({...form, child_request: data.value})}
                  label="Следующий запрос"
                  required
              />
              <Form.Input
                  value={form.value_in}
                  onChange={(e) => setForm({...form, value_in: e.target.value})}
                  error={errors.value_in || errors.non_field_errors}
                  label="Входное значение"
                  placeholder="Введите входное значение"
                  required
              />
              <Form.Button
                  width={5}
                  label="Добавить модель"
                  type="submit"
                  color="green"
              >
                <Icon size={"small"} name={"plus square outline"}/> Добавить
              </Form.Button>
            </Form.Group>
          </Form>)
        : <Header as="h4">Нет доступных запросов для создания новой связи</Header>}
      </Segment>
  )

}