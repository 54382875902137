import api from "shared/api";
import {appStore} from "app/redux/appStore";
import {addLog, saveLogs, saveTotalCount} from "entities/requests_logs/model/slice";


export const getAllRequestsLogs = async (filter, page = 1) => {
  if (filter.start_request < 1) {
    delete filter.start_request
  }
  return api.get("/ai_request/logs", {params: {...filter, page}})
    .then((response) => {
      appStore.dispatch(saveLogs(response.data.results))
      appStore.dispatch(saveTotalCount(response.data.count))
      return response.data
    })
}


export const sendAiRequest = async (payload) => {
  if (!payload.user_email) {
    payload.user_email = "test@test.ru"
  }
  return api.post("ai_request/send/", payload, {timeout: 60000})
    .then((response) => {
      payload.response = response.data
      appStore.dispatch(addLog(payload))
      return response.data
    })
}