import {Segment} from "semantic-ui-react";

import styles from "widgets/requests/request-row/styles.module.scss"
import {RequestFolderCard} from "entities/requests/ui/request-folder-card";
import {RequestFolderControl} from "features/request-folders/request-folder-control";

export const RequestFolderRow = ({folder}) => {

  return (
      <Segment className={styles.root}>
        <RequestFolderCard folder={folder}/>
        <RequestFolderControl folder={folder} />
      </Segment>
  )
}