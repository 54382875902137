import {Segment} from "semantic-ui-react";
import {RequestControl} from "features/requests/request-control";

import styles from "widgets/requests/request-row/styles.module.scss"
import {RequestCard} from "entities/requests/ui/request-card";

export const RequestRow = ({request}) => {

  return (
      <Segment className={styles.root}>
        <RequestCard request={request}/>
        <RequestControl request={request}/>
      </Segment>
  )
}