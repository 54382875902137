import {combineReducers} from "@reduxjs/toolkit";
import { userSlice } from "entities/user/model/slice";
import { stepsSlice} from "entities/steps/model/slice";
import { requestsSlice } from "entities/requests/model/slice";
import { requestsLogsSlice } from "entities/requests_logs/model/slice";

export const rootReducer = combineReducers({
  [userSlice.name]: userSlice.reducer,
  [stepsSlice.name]: stepsSlice.reducer,
  [requestsSlice.name]: requestsSlice.reducer,
  [requestsLogsSlice.name]: requestsLogsSlice.reducer
})