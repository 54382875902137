import {Route, Routes, Navigate} from "react-router-dom";
import {LoginPage} from "./login"
import {StepsListPage} from "./step-list"
import {useSelector} from "react-redux";
import {getIsAuth} from "entities/user/model/selectors";
import {useEffect} from "react";
import {getMe} from "entities/user/api";
import {Grid} from "semantic-ui-react";
import {MainSidebar} from "widgets/sidebar";
import {RequestsLogsListPage} from "pages/requests-logs-list";
import {RequestsListPage} from "pages/requests-list";
import {MainNavbar} from "widgets/navbar";
import {StepsDetailsPage} from "pages/step-details";
import {getAllSteps, getAllStepFolders} from "entities/steps/api";
import {RequestDetailsPage} from "pages/request-details";
import {TrialRequestPage} from "pages/trial-request";
import {StepFoldersPage} from "pages/step-folders";
import {RequestFoldersPage} from "pages/requests-folders";
import {getAllRequestFolders} from "entities/requests/api";


export const Routing = () => {
  const isAuth = useSelector(getIsAuth)

  const OnlyAuth = ({ children }) => {
    if (!isAuth) return <Navigate to="/login" />
    return children
  }

  const AlreadyAuth = ({ children }) => {
    if (isAuth) return <Navigate to="/" />
    return children
  }

  useEffect(() => {
    if (isAuth) {
      getMe()
      getAllSteps()
      getAllStepFolders()
      getAllRequestFolders()
    }
  }, [isAuth])

  return (
    <>
      <Grid celled>
        <Grid.Row columns="equal">
          {isAuth ?
            <Grid.Column width={3}>
              <MainSidebar />
            </Grid.Column>
          : null}
          <Grid.Column>
            <Grid.Row>
              {isAuth ? <MainNavbar /> : null}
            </Grid.Row>
            <Grid.Row>
              <Routes>
                <Route path="/steps" element={
                  <OnlyAuth>
                    <StepFoldersPage/>
                  </OnlyAuth>}
                />
                <Route path="/steps/folder/:folderId/" element={
                  <OnlyAuth>
                    <StepsListPage/>
                  </OnlyAuth>}
                />
                <Route path="/step/:stepId/" element={
                  <OnlyAuth>
                    <StepsDetailsPage/>
                  </OnlyAuth>}
                />
                <Route path="/requests" element={
                  <OnlyAuth>
                    <RequestFoldersPage/>
                  </OnlyAuth>}
                /><Route path="/requests/folder/:folderId/" element={
                  <OnlyAuth>
                     <RequestsListPage/>
                  </OnlyAuth>}
                />
                <Route path="/request/:requestId/" element={
                  <OnlyAuth>
                    <RequestDetailsPage/>
                  </OnlyAuth>}
                />
                <Route path="/requests_logs/" element={
                  <OnlyAuth>
                    <RequestsLogsListPage/>
                  </OnlyAuth>}
                />
                <Route path="/trial_request" element={
                  <OnlyAuth>
                    <TrialRequestPage/>
                  </OnlyAuth>}
                />
                <Route path="/login" element={
                  <AlreadyAuth>
                    <LoginPage/>
                  </AlreadyAuth>
                }/>
                <Route
                    path="/"
                    element={<Navigate to="/steps" replace />}
                />
                <Route
                    path="*"
                    element={<Navigate to="/" replace />}
                />
              </Routes>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}
