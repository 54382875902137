import styles from "pages/root.module.scss";
import {Divider, Header, Loader, Segment} from "semantic-ui-react";
import {RequestForm} from "features/requests/request-form";
import {RequestControl} from "features/requests/request-control";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {useEffect} from "react";
import {getCurrentRequest, getRequests} from "entities/requests/model/selectors";
import {getAllRequests, getRequest} from "entities/requests/api";
import {RequestConnectionRow} from "widgets/requests/request-connection-row";
import {RequestConnectionAdd} from "features/requests/request-connection-add";


export const RequestDetailsPage = () => {

  const {requestId} = useParams()
  const request = useSelector(getCurrentRequest)
  const all_requests = useSelector(getRequests)

  const handleAddRequest = () => {
    getRequest(requestId)
  }

  useEffect(() => {
    getRequest(requestId)
  }, [requestId])

  useEffect(() => {
    getAllRequests()
  }, [])

  return (
    <>{request ? (
      <>
        <Segment className={styles.segment}>
          <Header as={"h2"}>Редактирование шага {request.name}</Header>
          <RequestForm request={request} isNew={false}/>
          <Divider />
          <RequestControl request={request} innerText="Удалить запроса"/>
        </Segment>
        <Segment className={styles.segment}>
          <Header as={"h2"}>Связи между запросами</Header>
          <Divider/>
          <RequestConnectionAdd parent_request={request} onSave={handleAddRequest}/>
          <Divider/>
          {request.child_requests.map(child => (
            <RequestConnectionRow
              parent_request={request}
              child_request={all_requests[child.child_request]}
              connection_id={child.id}
              value_in={child.value_in}
              key={child.id}
            />
          ))}
        </Segment>
      </>
      )
     : <Loader active/>}
    </>
  )
}