import { appStore } from "app/redux/appStore";
import api from "shared/api";
import {
  saveRequest,
  saveRequests,
  clearRequest,
  setCurrentRequest,
  saveRequestFolders, saveRequestFolder, clearRequestFolder
} from "entities/requests/model/slice";

export const getAllRequests = async () => {
  return api.get("/ai_request/")
    .then((response) => {
      appStore.dispatch(saveRequests(response.data))
    })
}

export const getRequest = async (id) => {
  return api.get(`/ai_request/${id}/`)
    .then((response) => {
      appStore.dispatch(setCurrentRequest(response.data))
    })
}


export const createRequest = async (request) => {
  return api.post("/ai_request/", request)
      .then((response) => {
        appStore.dispatch(saveRequest(response.data))
      })
}

export const editRequest = async (request) => {
  return api.patch(`/ai_request/${request.id}/`, request)
      .then((response) => {
        appStore.dispatch(saveRequest(response.data))
      })
}

export const deleteRequestById = async (id) => {
  return api.delete(`/ai_request/${id}`)
      .then(() => {
        appStore.dispatch(clearRequest(id))
      })
}

export const createConnection = async (connection) => {
  return api.post('/ai_request/connections/', connection)
}

export const deleteConnection = async (id) => {
  return api.delete(`/ai_request/connections/${id}/`)
}

export const editConnection = async (connection_id, connection_data) => {
  return api.patch(`/ai_request/connections/${connection_id}/`, connection_data)
}

export const getAllRequestFolders = async () => {
  return api.get("/ai_request/folders")
    .then((response) => {
      appStore.dispatch(saveRequestFolders(response.data))
    })
}

export const createRequestFolder = async (request) => {
  return api.post("/ai_request/folders/", request)
      .then((response) => {
        appStore.dispatch(saveRequestFolder(response.data))
      })
}

export const editRequestFolder = async (request) => {
  return api.patch(`/ai_request/folders/${request.id}/`, request)
      .then((response) => {
        appStore.dispatch(saveRequestFolder(response.data))
      })
}

export const deleteRequestFolderById = async (id) => {
  return api.delete(`/ai_request/folders/${id}`)
      .then(() => {
        appStore.dispatch(clearRequestFolder(id))
      })
}