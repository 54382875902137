import {Header, Segment} from "semantic-ui-react";
import {useSelector} from "react-redux";
import {getStepFolders} from "entities/steps/model/selectors";
import styles from "pages/root.module.scss";
import {StepFolderAdd} from "features/step-folders/step-folder-add";
import {StepFolderRow} from "widgets/step-folders/step-folder-row";


export const StepFoldersPage = () => {

  const folders = useSelector(getStepFolders)

  return (
    <Segment  className={styles.segment}>
      <Header as={"h2"} textAlign={"center"}>
        Список папок с шагами
      </Header>
      <StepFolderAdd/>

      {Object.values(folders).map((folder, idx) => (
          <StepFolderRow folder={folder} key={idx}></StepFolderRow>
      ))}
    </Segment>
  )
}