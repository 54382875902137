import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  logs: {},
  totalCount: 0,
  currentPage: 1,
}

export const requestsLogsSlice = createSlice({
  name: 'requests_log',
  initialState,
  reducers: {
    saveLogs: (state, action) => {
      action.payload.forEach((log) => {
        state.logs[log.id] = log
      })
      // console.log(Array.from(new Set([...state.logs, ...action.payload])))
    },
    addLog: (state, action) => {
      state.logs[action.payload.id] = action.payload.id
    },
    saveTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    clearLogs: (state, action) => {
      state.logs = {}
    }
  },
})

// Action creators are generated for each case reducer function
export const { saveLogs, addLog, saveTotalCount, clearLogs } = requestsLogsSlice.actions
export default requestsLogsSlice.reducer
