import {useSelector} from "react-redux";
import {getCurrentCount, getTotalCount} from "entities/requests_logs/model/selectors";
import {Label} from "semantic-ui-react";


export const RequestLogListReview = () => {

  const totalCount = useSelector(getTotalCount)
  const currentCount = useSelector(getCurrentCount)

  return (
      <>
        <Label>Загружено запросов {currentCount} из {totalCount}</Label>
      </>
  )
}