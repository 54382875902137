import {Header, Icon, Menu} from "semantic-ui-react";
import styles from "widgets/sidebar/styles.module.scss";
import {NavLink} from "react-router-dom";

export const MainSidebar = () => {

  return (
    <>
      <Header as='h1' color='blue' textAlign='center' className={styles.header}>
        SSL GPT Admin
      </Header>
      <Menu className={styles.root} vertical fluid>
        <Menu.Item
          name="steps"
        >
          <NavLink to="/steps">
            <Icon name='folder' />
            Шаги
          </NavLink>
        </Menu.Item>
        <Menu.Item
          name="requests"
        >
          <NavLink to="/requests">
            <Icon name='arrow right'/>
            Запросы
          </NavLink>
        </Menu.Item>
        <Menu.Item
          name="requests"
        >
          <NavLink to="/requests_logs">
            <Icon name='globe'/>
            Логи запросов пользователей
          </NavLink>
        </Menu.Item>
        <Menu.Item
          name="trial_request"
        >
          <NavLink to="/trial_request">
            <Icon name='check circle'/>
            Пробный запрос
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  )
}