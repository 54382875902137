import {Button, Icon} from "semantic-ui-react";
import {useState} from "react";
import {RequestEditModal} from "widgets/requests/request-edit-modal";


export const RequestAdd = ({innerText = ""}) => {

  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
      <>
        <Button color="green" onClick={openModal}><Icon name="plus"/>{innerText}</Button>
        <RequestEditModal
          isOpen={isOpen}
          isNew={true}
          request={null}
          onClose={closeModal}
        />
      </>
  )
}