import {Header} from "semantic-ui-react";
import {Link} from "react-router-dom";


export const StepCard = ({step}) => {
  return (
    <div>
      <Header as="h3">
        <Link to={`/step/${step.id}`}>
          {step.name}
        </Link>
      </Header>
      <Header as="h4">
        {step.openai_model}
      </Header>
    </div>
  )
}