import {Link} from "react-router-dom";
import {Header, Icon, Popup} from "semantic-ui-react";
import {useSelector} from "react-redux";
import {getSteps} from "entities/steps/model/selectors";


export const RequestCard = ({request}) => {

  const steps = useSelector(getSteps)

  return (
    <div>
      <Header as="h3">
        <Link to={`/request/${request.id}`}>
          [{request.id}] {request.name}
        </Link>
      </Header>
      <Popup
        content={`Перейти к модели запроса ${steps[request.step].name}`}
        trigger={<Link to={`/step/${request.step}`}><Icon color="blue" name='folder'/></Link>}
      />
    </div>
  )
}