import {Button, Confirm, Icon} from "semantic-ui-react";
import {useState} from "react";
import {deleteStepFolderById} from "entities/steps/api";
import {StepFolderEditModal} from "widgets/step-folders/step-folder-edit-modal";


export const StepFolderControl = ({folder, innerText}) => {
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)

  const confirmDelete = async () => {
    await deleteStepFolderById(folder.id)
    toggleConfirm()
  }

  const toggleConfirm = () => {
    setConfirmIsOpen(!confirmIsOpen)
  }

  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
      <div>
        <Button color="red" basic onClick={toggleConfirm}><Icon name="trash alternate outline"/>{innerText}</Button>
        <Button color="orange" basic onClick={openModal}><Icon name="pencil"/></Button>
        <Confirm
          open={confirmIsOpen}
          header={`Вы действительно хотите удалить запрос ${folder.name}?`}
          content={null}
          onCancel={toggleConfirm}
          cancelButton="Отменить"
          onConfirm={confirmDelete}
          confirmButton="Удалить"
        />
        <StepFolderEditModal
          isOpen={isOpen}
          isNew={false}
          folder={folder}
          onClose={closeModal}
        />
      </div>
  )
}