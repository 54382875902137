import {Header, Segment} from "semantic-ui-react";
import styles from "pages/root.module.scss";
import {useSelector} from "react-redux";
import {getRequests} from "entities/requests/model/selectors";
import {RequestRow} from "widgets/requests/request-row";
import {useCallback, useEffect, useState} from "react";
import {getAllRequests} from "entities/requests/api";
import {RequestAdd} from "features/requests/request-add";
import {useParams} from "react-router";


export const RequestsListPage = () => {

  const {folderId} = useParams()
  const requests = useSelector( getRequests)
  const [folderRequests, setFolderRequests] = useState([])

  useEffect(() => {
    getAllRequests()
  }, [])

  useEffect(() => {
    setFolderRequests(Object.values(requests).filter((request) => request.folder === Number(folderId)))
  }, [folderId, requests])

  return (
      <Segment className={styles.segment}>
        <Header as={"h2"} textAlign={"center"}>
          Список шагов запросов
        </Header>
        <RequestAdd innerText={"Добавить новый запрос"}/>
        {folderRequests.map((request) => (
            <RequestRow request={request} key={request.id}/>
        ))}
      </Segment>
  )
}