import {useEffect, useState} from "react";
import {getAllRequestsLogs} from "entities/requests_logs/api";
import {Button, Divider, Header, Segment} from "semantic-ui-react";
import styles from "pages/root.module.scss"
import {useDispatch, useSelector} from "react-redux";
import {getRequestsLogs} from "entities/requests_logs/model/selectors";
import {RequestListHeader} from "widgets/requests/request-list-header";
import {RequestLogRow} from "widgets/requests/request_log-row";
import {getAllRequests} from "entities/requests/api";
import {clearLogs} from "entities/requests_logs/model/slice";

export const RequestsLogsListPage = () => {
  const dispatch = useDispatch()
  const logs = useSelector(getRequestsLogs)
  const [sortedLogs, setSortedLogs] = useState({})
  const [nextPage, setNextPage] = useState(1)
  const [listFilter, setListFilter] = useState({})
  const [sortOrder, setSortOrder] = useState({})
  const [showNextBtn, setShowNextBtn] = useState(true)

  const onFilterChange = (filter) => {
    if (filter !== listFilter) {
      setListFilter(filter)
      setNextPage(1)
      setShowNextBtn(true)
    }
  }

  const onSortChange = (sortOrder) => {
    setSortOrder(sortOrder)
  }

  const loadLogs = () => {
    getAllRequestsLogs(listFilter, nextPage)
      .then((data) => {
        if (data.next) {
          // const urlParams = new URLSearchParams(data.next); поиск параметров так не работает
          const pageRe = /page=(\d+)/
          setNextPage(Number(pageRe.exec(data.next)[1]))
        }
        else {
          setShowNextBtn(false)
        }
      })
  }

  useEffect(() => {
    dispatch(clearLogs())
    loadLogs()
  }, [listFilter])

  useEffect(() => {
    setSortedLogs(Object.values(logs).sort((log_1, log_2) => {
      return sortOrder ? log_1.id - log_2.id : log_2.id - log_1.id
    }))
  }, [logs, sortOrder])

  useEffect(() => {
    getAllRequests()
  }, [])

  return (
    <>
      <Segment className={styles.segment}>
        <Header textAlign={"center"} as={"h2"}>Список запросов пользователей</Header>
        <RequestListHeader onFilterChange={onFilterChange} onSortChange={onSortChange}/>
        {Object.values(sortedLogs).map((request, idx) => (
          <RequestLogRow request={request} key={idx}/>
        ))}
        <Divider />
        {showNextBtn ? <Button onClick={loadLogs}>Загрузить еще</Button> : null}
      </Segment>
    </>
  )
}