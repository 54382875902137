import {Grid, Header} from "semantic-ui-react";

import {LoginForm} from "features/login-form";

export const LoginPage = () => {

    return (
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='blue' textAlign='center'>
            SSL GPT Admin
          </Header>
          <LoginForm />
        </Grid.Column>
      </Grid>
    )
}

