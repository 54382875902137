import {Form, Icon} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {deleteConnection, editConnection} from "entities/requests/api";
import {toast} from "react-toastify";


export const RequestConnectionControl = ({connection_id, connection_value_in, onSave}) => {
  const [valueIn, setValueIn] = useState("")
  const [errors, setErrors] = useState({})

  const handleSave = () => {
    editConnection(connection_id, {value_in: valueIn})
      .then(() => {
        onSave(false)
        setErrors([])
      })
      .catch((error) => {
        setErrors(error.response?.data || [])
      })
  }

  const handleDelete = () => {
    deleteConnection(connection_id)
      .then(() => {
        toast.success("Связь успешно удалена!")
        onSave(true)
      })
  }

  useEffect(() => {
    setValueIn(connection_value_in)
  }, [connection_value_in])

  return (
      <div>
        <Form>
          <Form.Group widths={"equal"}>
            <Form.Input
                value={valueIn}
                onChange={(e) => setValueIn(e.target.value)}
                error={errors.non_field_errors || errors.value_in}
                label="Входное значение"
                placeholder="Введите входное значение"
                required
            />
            <Form.Button onClick={handleSave} label="Сохранить" width={2} color="green"><Icon name={"save"}/></Form.Button>
            <Form.Button onClick={handleDelete} label="Удалить связь" width={2} color="red"><Icon name={"trash alternate outline"}/></Form.Button>
          </Form.Group>
        </Form>
      </div>
  )

}