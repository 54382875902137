import {Button, Confirm, Icon} from "semantic-ui-react";
import {deleteRequestById} from "entities/requests/api";
import {useState} from "react";


export const RequestControl = ({request, innerText}) => {
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)

  const confirmDelete = async () => {
    await deleteRequestById(request.id)
    toggleConfirm()
  }

  const toggleConfirm = () => {
    setConfirmIsOpen(!confirmIsOpen)
  }

  return (
      <div>
        <Button color="red" basic onClick={toggleConfirm}><Icon name="trash alternate outline"/>{innerText}</Button>
        <Confirm
          open={confirmIsOpen}
          header={`Вы действительно хотите удалить запрос ${request.name}?`}
          content={null}
          onCancel={toggleConfirm}
          cancelButton="Отменить"
          onConfirm={confirmDelete}
          confirmButton="Удалить"
        />
      </div>
  )
}