import {Button, Header, Icon, Segment} from "semantic-ui-react";
import {useSelector} from "react-redux";
import {getUser} from "entities/user/model/selectors";
import styles from "widgets/navbar/styles.module.scss";
import {logout} from "entities/user/api";


export const MainNavbar = () => {
  const user = useSelector(getUser)

  return (
      <Segment className={styles.root}>
        <Header className={styles.header}>
          Вы авторизованы как: {user?.name}
        </Header>
        <Button
          color="red"
          onClick={logout}
          className={styles.exit_btn}
        >
          <Icon name="sign-out"/> Выйти
        </Button>
      </Segment>
  )
}