import {Divider, Form, Header, Icon, Label, Popup, Segment} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getRequests} from "entities/requests/model/selectors";
import {Link} from "react-router-dom";
import {sendAiRequest} from "entities/requests_logs/api";
import {RequestLogCard} from "entities/requests_logs/ui/request-log-card";


export const TrialRequestForm = () => {

  const [form, setForm] = useState({})
  const [trailRequest, setTrailRequest] = useState({})
  const [previewIsLoad, setPreviewIsLoad] = useState(false)
  const [requestOptions, setRequestOptions] = useState([])
  const [errors, setErrors] = useState({})

  const requests = useSelector(getRequests)

  const handleSubmit = (e) => {
    e.preventDefault()
    setPreviewIsLoad(true)
    sendAiRequest({...form, params: JSON.parse(form.params)})
      .then(data => {
        console.log(2, data)
        setErrors({})
        setTrailRequest(data)
        setPreviewIsLoad(false)
      })
      .catch(error => {
        setErrors(error.response?.data || {})
        setPreviewIsLoad(false)
      })
  }

  useEffect(() => {
    const options = []
    Object.values(requests).forEach((request) => {
      options.push({key: request.id, value: request.id, text: `[${request.id}] ${request.name}`})
    })
    setRequestOptions(options)
  }, [requests])


  return (
    <>
      <Segment>
        <Form onSubmit={handleSubmit}>
          <Form.Select
              placeholder="Выберите запрос"
              options={requestOptions}
              value={form.start_request}
              onChange={(e, data) => setForm({...form, start_request: data.value})}
              error={errors.start_request}
              label="Запрос"
              required
          />
          {requests[form.start_request] ? (
            <Popup
              content="Перейти к запросу"
              trigger={
                <Link to={`/requests/${form.start_request}`}>
                  <Label color="blue" pointing basic><Icon color="blue" name='folder'/>
                    {requests[form.start_request].name}
                  </Label>
                </Link>
              }
            />)
          : null}
          <Form.TextArea
              value={form.params}
              onChange={(e) => setForm({...form, params: e.target.value})}
              error={errors.params}
              label="Параметры запроса (JSON)"
              placeholder="Введите параметры запроса"
          />
          <Form.Button disabled={previewIsLoad} loading={previewIsLoad} color="green" type="submit">Отправить</Form.Button>
        </Form>
      </Segment>
      {Object.keys(trailRequest).length ? (
        <>
          <Divider />
          <Header as={"h2"}>Результат запроса</Header>
          <RequestLogCard request={trailRequest}/>
        </>
        ) : null
      }
    </>
  )
}