import { appStore } from "app/redux/appStore";
import api from "shared/api";
import {
  clearStep, clearStepFolder,
  saveStep,
  saveStepFolder,
  saveStepFolders,
  saveSteps,
  setCurrentStep
} from "entities/steps/model/slice";


export const getAllSteps = async () => {
  return api.get("/steps/")
    .then((response) => {
      appStore.dispatch(saveSteps(response.data))
    })
}


export const getStep = async (id) => {
  return api.get(`/steps/${id}/`)
    .then((response) => {
      appStore.dispatch(setCurrentStep(response.data))
    })
}

export const createStep = async (step) => {
  return api.post("/steps/", step)
      .then((response) => {
        appStore.dispatch(saveStep(response.data))
      })
}


export const editStep = async (step) => {
  return api.patch(`/steps/${step.id}/`, step)
      .then((response) => {
        appStore.dispatch(saveStep(response.data))
      })
}

export const deleteStepById = async (id) => {
  return api.delete(`/steps/${id}`)
      .then(() => {
        appStore.dispatch(clearStep(id))
      })
}

export const getAllStepFolders = async () => {
  return api.get("/steps/folders")
    .then((response) => {
      appStore.dispatch(saveStepFolders(response.data))
    })
}

export const createStepFolder = async (folder) => {
  return api.post("/steps/folders/", folder)
      .then((response) => {
        appStore.dispatch(saveStepFolder(response.data))
      })
}

export const editStepFolder = async (step) => {
  return api.patch(`/steps/folders/${step.id}/`, step)
      .then((response) => {
        appStore.dispatch(saveStepFolder(response.data))
      })
}

export const deleteStepFolderById = async (id) => {
  return api.delete(`/steps/folders/${id}`)
      .then(() => {
        appStore.dispatch(clearStepFolder(id))
      })
}
