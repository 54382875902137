import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  steps: {},
  folders: {},
  currentStep: null,
}

export const stepsSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    saveStep: (state, action) => {
      state.steps[action.payload.id] = action.payload
    },
    saveSteps: (state, action) => {
      action.payload.forEach((step) => state.steps[step.id] = step)
    },
    clearStep: (state, action) => {
      delete state.steps[action.payload]
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    saveStepFolder: (state, action) => {
      state.folders[action.payload.id] = action.payload
    },
    saveStepFolders: (state, action) => {
      action.payload.forEach((folder) => state.folders[folder.id] = folder)
    },
    clearStepFolder: (state, action) => {
      delete state.folders[action.payload]
    }
  },
})

// Action creators are generated for each case reducer function
export const { saveStep, saveSteps, setCurrentStep, clearStep, saveStepFolders, saveStepFolder, clearStepFolder } = stepsSlice.actions
export default stepsSlice.reducer
