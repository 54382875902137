import {Form} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {createStepFolder, editStepFolder} from "entities/steps/api";

export const StepFolderForm = ({isNew, folder, onSave}) => {


  const DEFAULT_FORM = {
    name: "folder",
    slug: "folder_slug"
  }

  const [form, setForm] = useState(DEFAULT_FORM)
  const [errors, serErrors] = useState({})

  const handleSubmit = (e) => {
    e.preventDefault()
    if (isNew) {
      createStepFolder(form)
        .then(() => {
          toast.success(`Новая папка ${form.name} успешно создана!`)
          serErrors([])
          onSave()
        })
        .catch((error) => {
          serErrors(error.response?.data || [])
        })
    } else {
      editStepFolder(form)
        .then(() => {
          toast.success(`Папка ${form.name} успешно обновлена!`)
          serErrors([])
          onSave()
        })
        .catch((error) => {
          serErrors(error.response?.data || [])
        })
    }
  }

  const resetForm = () => {
    setForm({...DEFAULT_FORM, id: folder?.id})
  }
  useEffect(() => {
    if (!isNew) {
      setForm(folder)
    }
  }, [folder, isNew])


  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Input
          value={form.name}
          onChange={(e) => setForm({...form, name: e.target.value})}
          error={errors.name}
          label="Название папки с запросами"
          placeholder="Введите название папки c запросами"
          required
        />
        <Form.TextArea
          value={form.slug}
          onChange={(e) => setForm({...form, slug: e.target.value})}
          error={errors.slug}
          label="Slug"
          placeholder="Введите slug"
          required
        />
        <Form.Button color="orange" basic onClick={resetForm}>Сбросить</Form.Button>
        <Form.Button color="green" type="submit">Сохранить</Form.Button>
      </Form>
    </>
  )
}
