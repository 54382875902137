import {Button, Icon} from "semantic-ui-react";
import {StepEditModal} from "widgets/steps/step-edit-modal";
import {useState} from "react";


export const StepAdd = () => {

  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }


  return (
      <>
        <Button color="green" onClick={openModal}><Icon name="plus"/>Добавить модель</Button>
        <StepEditModal
          isOpen={isOpen}
          isNew={true}
          step={null}
          onClose={closeModal}
        />
      </>
  )
}