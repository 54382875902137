import {Button, Modal} from "semantic-ui-react";
import {StepFolderForm} from "features/step-folders/step-folder-form";

export const StepFolderEditModal = ({isOpen, folder, isNew, onOpen, onClose}) => {

  return (
    <Modal
      closeIcon
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Modal.Header>
        {isNew ? 'Создание новой папки' : `Окно редактирования папки ${folder?.name}`}
      </Modal.Header>
      <Modal.Content>
        <StepFolderForm isNew={isNew} folder={folder} onSave={onClose}/>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" onClick={onClose}>Отменить</Button>
      </Modal.Actions>
    </Modal>
  )
}