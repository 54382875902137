import {Divider, Header, Segment} from "semantic-ui-react";
import styles from "pages/root.module.scss";
import {useSelector} from "react-redux";
import {getSteps} from "entities/steps/model/selectors";
import {StepRow} from "widgets/steps/step-row";
import {StepAdd} from "features/steps/step-add";
import {useCallback} from "react";
import {useParams} from "react-router";
export const StepsListPage = () => {
  const {folderId} = useParams()
  const steps = useSelector(getSteps)

  const getFolderSteps = useCallback((folderId) => {
    return Object.values(steps).filter((step) => {
      return step.folder === Number(folderId)
    })
  }, [steps])

  return (
    <Segment className={styles.segment}>
      <Header as={"h2"} textAlign={"center"}>
        Список шагов
      </Header>
      <StepAdd />
      <Divider/>
      {getFolderSteps(folderId).map((step) => (
          <StepRow step={step} key={step.id}/>
      ))}
    </Segment>
  )
}
